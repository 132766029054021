<template>
  <v-container
    fluid
    class="pa-0"
  >
    <v-card>
      <v-toolbar
        flat
        color="transparent"
      >
        <v-text-field
          v-model="options.search"
          rounded
          dense
          outlined
          hide-details
          label="搜索"
          placeholder="按价目表名称搜索"
          :append-icon="icons.mdiMagnify"
          class="flex-grow-0"
          @click:append="getDesserts"
        />
        <v-spacer></v-spacer>
        <v-btn
          v-permission="['70001']"
          color="primary"
          dark
          @click="newItemOpenDialog"
        >
          添加价目表
        </v-btn>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="desserts"
        :items-per-page.sync="options.pageSize"
        :page.sync="options.page"
        :server-items-length="totalDesserts"
        :loading="loading"
        :disable-sort="true"
        :footer-props="{itemsPerPageOptions: [10, 15, 20, 50, 100]}"
        @update:items-per-page="getDesserts"
        @update:page="getDesserts"
      >
        <template v-slot:item.created_at="{ item }">
          {{ item.created_at | dateFormat }}
        </template>
        <template v-slot:item.updated_at="{ item }">
          {{ item.updated_at | dateFormat }}
        </template>
        <template
          v-slot:item.actions="{ item }"
        >
          <v-btn
            v-permission="['70002']"
            icon
            @click="detailBtnClick(item)"
          >
            <v-icon>{{ icons.mdiArrowRightCircle }}</v-icon>
          </v-btn>
          <v-btn
            v-permission="['70004']"
            icon
            @click="deleteBtnClick(item)"
          >
            <v-icon>{{ icons.mdiDelete }}</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog
      v-model="createDialog"
      max-width="500"
      transition="dialog-bottom-transition"
    >
      <v-card
        class="pa-sm-10"
      >
        <v-card-title class="justify-center text-h5">
          新增价目表
        </v-card-title>
        <v-card-text class="text-center mt-n2">
          创建新的价目表
        </v-card-text>
        <v-card-text class="mt-5">
          <v-form
            ref="priceListFormRef"
            lazy-validation
          >
            <v-text-field
              v-model="row.name"
              label="价目表名"
              :rules="[rules.required, rules.counter]"
              validate-on-blur
              counter
              maxlength="20"
              dense
              outlined
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-center mt-3">
          <v-btn
            color="success"
            dark
            @click="createDialogSubmit"
          >
            保存
          </v-btn>
          <v-btn
            outlined
            @click="cancelDialog"
          >
            取消
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deleteDialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          确定要删除这条数据吗?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            @click="cancelDelete"
          >
            取消
          </v-btn>
          <v-btn
            color="success"
            @click="deleteItem"
          >
            确认
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {
  getPriceLists,
  addPriceList,
  deletePriceList,
} from '@/api/goods'
import {
  mdiMagnify, mdiDelete, mdiArrowRightCircle,
} from '@mdi/js'

export default {
  name: 'PriceList',
  data() {
    return {
      desserts: [],
      totalDesserts: 0,
      createDialog: false,
      deleteDialog: false,
      rowId: null,
      row: {
        name: null,
      },
      loading: true,
      rules: {
        required: value => !!value || '必填.',
        counter: value => (value && value.length <= 20) || '最大20个字符',
      },
      headers: [
        { text: '价目表名称', value: 'name' },
        { text: '商户数量', value: 'merchant_count' },
        { text: '创建时间', value: 'created_at' },
        { text: '更新时间', value: 'updated_at' },
        { text: '操作', align: 'center', value: 'actions' },
      ],
      icons: {
        mdiMagnify,
        mdiDelete,
        mdiArrowRightCircle,
      },
      options: {
        page: 1,
        pageSize: 10,
        search: '',
      },
    }
  },

  created() {
    this.getDesserts()
  },

  methods: {
    getDesserts() {
      this.loading = true
      getPriceLists(this.options).then(response => {
        this.desserts = response.data.results
        this.totalDesserts = response.data.count
        this.loading = false
      })
    },
    detailBtnClick(item) {
      this.$router.push({ name: 'priceListDetail', query: { id: item.id } })
    },
    deleteBtnClick(item) {
      this.rowId = item.id
      this.deleteDialog = true
    },
    cancelDelete() {
      this.rowId = null
      this.deleteDialog = false
    },

    async deleteItem() {
      await deletePriceList(this.rowId)
      this.rowId = null
      this.deleteDialog = false
      this.$toast.success('删除成功')
      this.options.page = 1
      this.getDesserts()
    },
    async newItemOpenDialog() {
      this.row = {
        name: '',
      }
      this.createDialog = true
    },
    async createDialogSubmit() {
      if (!this.$refs.priceListFormRef.validate()) return
      const result = await addPriceList(this.row)
      this.$toast.success('创建成功')
      await this.$router.push({
        name: 'priceListDetail',
        query: { id: result.data.id },
      })
    },

    cancelDialog() {
      this.$refs.priceListFormRef.reset()
      this.createDialog = false
    },
  },
}
</script>
