import request from '@/utils/request'

export function getPriceLists(params) {
  return request.get('/api/v1/backend/pricelists', { params })
}

export function addPriceList(data) {
  return request.post('/api/v1/backend/pricelists', data)
}

export function getPriceList(id) {
  return request.get(`/api/v1/backend/pricelists/${id}`)
}

export function updatePriceList(id, data) {
  return request.put(`/api/v1/backend/pricelists/${id}`, data)
}

export function getPriceListItems(id, params) {
  return request.get(`/api/v1/backend/pricelists/${id}/items`, { params })
}

export function deletePriceList(id) {
  return request.delete(`/api/v1/backend/pricelists/${id}`)
}

export function getCategories(params) {
  return request.get('/api/v1/backend/categories', { params })
}

export function addCategory(data) {
  return request.post('/api/v1/backend/categories', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export function updateCategory(id, data) {
  return request.put(`/api/v1/backend/categories/${id}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export function deleteCategory(id) {
  return request.delete(`/api/v1/backend/categories/${id}`)
}

export function getOptions(params) {
  return request.get('/api/v1/backend/options', { params })
}

export function addOption(data) {
  return request.post('/api/v1/backend/options', data)
}

export function getOption(id) {
  return request.get(`/api/v1/backend/options/${id}`)
}

export function updateOption(id, data) {
  return request.put(`/api/v1/backend/options/${id}`, data)
}

export function deleteOption(id) {
  return request.delete(`/api/v1/backend/options/${id}`)
}

export function getGoods(params) {
  return request.get('/api/v1/backend/goods', { params })
}

export function addGood(data) {
  return request.post('/api/v1/backend/goods', data)
}

export function getGood(id) {
  return request.get(`/api/v1/backend/goods/${id}`)
}

export function updateGood(id, data) {
  return request.put(`/api/v1/backend/goods/${id}`, data)
}

export function deleteGood(id) {
  return request.delete(`/api/v1/backend/goods/${id}`)
}
